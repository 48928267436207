// React
import React from 'react'

// Styling
import ScrollContainer from './scroll.styles'

const Scroll = () => {

    return (
        <ScrollContainer>
            <div className='icon-scroll'></div>
        </ScrollContainer>
    )
}

export default Scroll