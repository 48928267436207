import styled from 'styled-components'
import vars from '../../../../assets/vars.jsx'

const MailingListContainer = styled.div`
    width: 100%;

    background-color: red;
    /* background: linear-gradient(324deg, rgba(255,0,0,1) 0%, rgba(143,0,0,1) 100%); */

    /* background-image: url(${process.env.PUBLIC_URL + '/assets/images/grass.png'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top; */

    :before, :after {
        content: "";
        background-color: red;
        /* background: linear-gradient(324deg, rgba(255,0,0,1) 0%, rgba(143,0,0,1) 100%); */
        height: 80px;
        transform: skewY(-2deg);
        position: absolute;
        margin-top: -40px;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .contactContainer {
        padding-top: 20px;
        padding-bottom: 20px;

        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 1px red solid; */

        h1 {
            color: white;
            font-size: 60px;
            text-align: center;
            font-family: 'Longhaul' !important;
            margin: 0 0 30px 0;
        }

        .emailForm {
            display: flex;
            flex-direction: row;

            .emailInput {
                width: 400px;
                transform: skew(-20deg);

                .MuiOutlinedInput-root {
                    background-color: white;
                    border-radius: 0;
                }

                .MuiOutlinedInput-input {
                    padding: 18.5px 20px;
                    transform: skew(20deg)
                }
            }

            .submitBtn {
                height: 56px;
                background-color: white;
                margin-left: 25px;
                padding: 5px 15px;

                box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);

                :hover {
                    box-shadow: none;
                }

                display: flex;
                align-items: center;

                cursor: pointer;

                transform: skew(-20deg);

                p {
                    transform: skew(20deg);
                    font-size: 25px;
                    text-align: center;
                    font-family: 'Longhaul' !important;
                    color: red;
                    margin: 0;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .contactContainer {
            .emailForm {
                flex-direction: column;
                align-items: center;

                .emailInput {
                    width: 350px;
                }
                
                .submitBtn {
                    width: fit-content;
                    margin-top: 20px;
                    margin-left: 0;
                }

            }
        }
    }
`

export default MailingListContainer
