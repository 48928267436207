// React
import React from 'react'

// Material UI
import Container from '@material-ui/core/Container';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

// Styling
import ContactContainer from './contact.styles'

// Components
import Social from '../social/social.component.jsx'

const Contact = () =>  {

    return (
        <ContactContainer>
            <Container id='contact' className='contactContainer'>
                
                <h1>CONTACT US</h1>

                <div className='contactRow' onClick={() => window.location.href = 'tel:07828566926'}>
                    <div className='boxSkew'><PhoneIphoneIcon /></div>
                    <div className='boxSkew'><p>07828566926</p></div>
                </div>

                <div className='contactRow' onClick={() => window.location.href = 'mailto:BootsReborn@yahoo.com'}>
                    <div className='boxSkew'><MailOutlineIcon /></div>
                    <div className='boxSkew'><p>BootsReborn@yahoo.com</p></div>
                </div>

                <Social contactUs/>

            </Container>
        </ContactContainer>
    )
}

export default Contact