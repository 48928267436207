// React
import React from 'react'

// Redux
import { connect } from 'react-redux'
import { setModal } from "../../redux/home/home.actions";

// Styling
import ModalContainer from './modal.styles'

const Modal = (props) => {

    return (
        <ModalContainer>
            <div className='modalDiv'>
                <h1>{props.modal.title}</h1>

                <p>{props.modal.text}</p>
                
                <div className='closeButton' onClick={() => props.setModal({show: false, title: '', text: ''})}><h4>CLOSE</h4></div>
            </div>
        </ModalContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    setModal: user => dispatch(setModal(user))
})

const mapStateToProps = state => ({
    modal: state.home.modal
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)