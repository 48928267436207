// React
import React from 'react'
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom"

// Redux
import { connect } from 'react-redux'
import { setCurrentUser } from "./redux/user/user.actions";

// Firebase
import { auth, firestore } from './utils/firebase.utils.js'

// Pages
import HomePage from './pages/home/home.page.jsx'

// Components
import Cookies from "./components/cookies/cookies.component";
import ScrollTop from "./components/scrollTop/scrollTop.component";
import Modal from "./components/modal/modal.component";

import smoothscroll from 'smoothscroll-polyfill';


// Logger
import Logger from './utils/logger.utils.js'
const Log = new Logger()
Log.init("App")
 
// kick off the polyfill!
smoothscroll.polyfill();

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            route: window.location.pathname
        }

    }

    componentDidMount() {
        Log.info("componentDidMount()")

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('Route: ', window.location.pathname )
        if (window.location.pathname !== this.state.route) {
            this.setState({route: window.location.pathname})
        }
    }


    render() {
        return (
            <Router >
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                    {/*<Route component={NoPage}/>*/}
                </Switch>
                {this.props.showLoadingScreen && <LoadingScreen />}
                <Cookies />
                <ScrollTop />
                {this.props.modal.show && <Modal />}
            </Router>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
})

const mapStateToProps = state => ({
    user: state.user.user,
    showLoadingScreen: state.home.showLoadingScreen,
    modal: state.home.modal
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
