import styled from 'styled-components'
import vars from '../../../../assets/vars.jsx'

const FreeShippingContainer = styled.div`
    display: flex;
    flex-direction: row;

    /* position: absolute;
    left: 50px;
    top: 30px; */
    margin-top: 20px;

    background-color: white;
    padding: 5px 15px;
    width: fit-content;

    margin:  20px 10px 0 10px;

    transform: skew(-20deg);

    box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);
    
    animation: fadeIn 0.5s 0.5s both linear;

    h4 {
        color: black;
        transform: skew(20deg);
        margin: 5px 0 0 0;
        font-size: 25px;
        font-family: 'Longhaul' !important;
        text-align: center;
    }

    @media (max-width: 768px) {


        h4 {
            font-size: 20px;
        }
    }

    
`

export default FreeShippingContainer
