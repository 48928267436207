// React
import React, { useState } from 'react'

// Styling
import CookieContainer from './cookies.styles'

// Material UI
import Container from '@material-ui/core/Container';

const Cookies = () => {

    const [ showCookiesBanner, setCookiesBanner ] = useState(!localStorage.getItem('cookies'))

    const closeCookies = () => {
        localStorage.setItem('cookies', new Date())
        setCookiesBanner(false)
    }

    return (<>
        {showCookiesBanner && <CookieContainer>
            <Container className='cookiesWidth'>
                <p>By using this website acknowledge that we use <a href='https://en.wikipedia.org/wiki/HTTP_cookie' target='_blank'>COOKIES</a> to offer you a better experience and analyse traffic.</p>
                <div className='closeBtn' onClick={closeCookies}><p>CLOSE</p></div>
            </Container>
        </CookieContainer>}
    </>)
}

export default Cookies