const INITIAL_STATE = {
    modal: {
        show: false,
        title: '',
        text: ''
    },
}

const HomeReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_MODAL':
            return {
                ...currentState,
                modal: action.payload
            }
        default:
            return currentState
    }
}

export default HomeReducer;
