// React
import React from 'react'

// Material UI
import CropSquareIcon from '@material-ui/icons/CropSquare';

// Styling
import MenuContainer from './menu.styles'

import { scrollToServices, scrollToContact } from '../../utils/scroll.js'

const Menu = () => {

    return (
        <MenuContainer>
            <div className="menuItem"><p>HOME</p></div>
            <CropSquareIcon />
            <div className="menuItem" onClick={scrollToServices}><p>SERVICES</p></div>
            <CropSquareIcon />
            <div className="menuItem" onClick={scrollToContact}><p>CONTACT</p></div>
        </MenuContainer>
    )
}

export default Menu