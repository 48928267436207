import styled from 'styled-components'
import vars from '../../../../assets/vars.jsx'

const ServicesContainer = styled.div`
    width: 100%;

    background-color: black;
    /* background: linear-gradient(324deg, rgba(59,59,59,1) 0%, rgba(0,0,0,1) 100%); */


    /* background-image: url(${process.env.PUBLIC_URL + '/assets/images/grass.png'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom; */

    .servicesContainer {
        padding-top: 50px;
        padding-bottom: 50px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 1px red solid; */

        h1 {
            color: white;
            font-size: 60px;
            text-align: center;
            font-family: 'Longhaul' !important;
            margin: 10px 0 50px 0;
        }

        .serviceBox {
            /* border: 1px solid ${vars.pink}; */
            margin-bottom: 20px;

            .serviceImage {
                width: 100%;
                height: 200px;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);                
            }

            img {
                width: 100%;
            }

            h3 {
                color: white;
                font-family: 'Longhaul' !important;
                margin: 20px 0 0 0;
                text-align: center;
            }

            p {
                color: white;
                text-align: center;
                padding: 0 20px;
            }
        }
    }

    @media (max-width: 768px) {
        .servicesContainer {
            .serviceBox {
                padding: 0 40px;

                .serviceImage {
                    height: 300px;
                }
            }
        }
    }
`

export default ServicesContainer
