// React
import React from 'react'

// Styling
import SocialContainer from './social.styles'

const Social = (props) => {

    return (
        <SocialContainer className={props.contactUs ? 'contactUsSocial' : 'spalshSocial'}>
            <a href='https://www.facebook.com/boots.reborn.3' target="_blank" ><div className="socialIcon"><img src={process.env.PUBLIC_URL + '/assets/images/social/facebook.svg'}/></div></a>
            <a href='https://www.instagram.com/bootsreborn/' target="_blank" ><div className="socialIcon"><img src={process.env.PUBLIC_URL + '/assets/images/social/insta.svg'}/></div></a>
            <a href='https://twitter.com/boots_reborn' target="_blank" ><div className="socialIcon"><img src={process.env.PUBLIC_URL + '/assets/images/social/twitter.svg'}/></div></a>
        </SocialContainer>
    )
}

export default Social