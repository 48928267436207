const INITIAL_STATE = {
    user: {
        id: ''
    }
}

const UserReducer = (currentState = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...currentState,
                user: action.payload
            }
        default:
            return currentState
    }
}

export default UserReducer;
