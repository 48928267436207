import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import Logger from './logger.utils.js';

const Log = new Logger();
Log.init('firebase.utils');

const config = {
    apiKey: "AIzaSyCl9fSa24POtd_jIJU6n9m64QR4WVOj3OY",
    authDomain: "bootsreborn-67990.firebaseapp.com",
    projectId: "bootsreborn-67990",
    storageBucket: "bootsreborn-67990.appspot.com",
    messagingSenderId: "185117539920",
    appId: "1:185117539920:web:9e24fda2a4095d4fa5db55",
    measurementId: "G-N3HR9724Q0"
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();


const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

export const signInWithFacebook = async () => {
    Log.info("signInWithFacebook()");
    return await firebase.auth().signInWithPopup(facebookProvider)
}

export const signInWithGoogle = async () => {
    Log.info("signInWithGoogle()");
    return await firebase.auth().signInWithPopup(googleProvider)
}

export const signInWithApple = async () => {
    Log.info("signInWithApple()");
    return await firebase.auth().signInWithPopup(appleProvider)
}

export const signInWithAppleMobile = async (appleResponse) => {
    Log.info("signInWithApple()");

    const credential = appleProvider.credential({
        idToken: appleResponse.identityToken
    });

    console.log("credential: ", credential)

    let signInResponse = await firebase.auth().signInWithCredential(credential)
    console.log("SignInResponse:", signInResponse)
    return signInResponse;
}

export const signOut = () => {
    Log.info("signOut()");
    firebase.auth().signOut();
}

export default firebase;
