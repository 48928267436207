import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;

    position: absolute;
    left: 50px;
    /* top: 30px; */

    height: 40px;

    background-color: white;
    padding: 0 7px;

    transform: skew(-20deg);

    box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);

    animation: dropDown 0.3s 0.5s linear both;

    svg {
        margin-top: 7px;
    }

    .menuItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
        p {
            color: black;
            margin: 4px 0 0 0;
            font-size: 20px;
            transform: skew(20deg);
            font-family: 'Longhaul' !important;
        }
    }

    @media (max-width: 600px) {
        left: 50%;
        margin-left: -178px
    }

`

export default MenuContainer
