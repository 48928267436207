// React
import React, { useState } from 'react'

// Styling
import ScrollTopContainer from './scrollTop.styles'

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { scrollTop } from '../../utils/scroll.js'

const ScrollTop = () => {

    const [ showScrollTop, setScrollTop ] = useState(false)

    window.addEventListener("scroll", () => {
        setScrollTop(window.pageYOffset > 50)
    })

    return (<>
        {showScrollTop && <ScrollTopContainer onClick={scrollTop}>
            <ArrowUpwardIcon />
        </ScrollTopContainer>}
    </>)
}

export default ScrollTop