// React
import React, { useState } from 'react'

// Redux
import { connect } from 'react-redux'
import { setModal } from "../../../../redux/home/home.actions";

import { firestore } from "../../../../utils/firebase.utils";

// Material UI
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

// Styling
import MailingListContainer from './mailingList.styles'

const Contact = (props) =>  {
    const [ email, setEmail ] = useState('')

    const submitEmail = () => {
        if (validateEmail(email)) {
            firestore.collection('mailingList').add({
                email: email,
                dateAdded: new Date()
            }).then(() => {
                props.setModal({show: true, title: 'Success', text: 'You email has been added to our mailing list'})
                setEmail('')
            })
        } else {
            props.setModal({show: true, title: 'Error', text: 'Please enter a valid email address'})
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <MailingListContainer>
            <Container className='contactContainer'>

                <h1>JOIN OUR MAILING LIST</h1>

                <div className='emailForm' >
                    <TextField className='emailInput' required variant='outlined' placeholder='YOUR EMAIL ADDRESS' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <div className='submitBtn' onClick={submitEmail}><p>SUBMIT</p></div>
                </div>

            </Container>
        </MailingListContainer>
    )
}


const mapDispatchToProps = dispatch => ({
    setModal: user => dispatch(setModal(user))
})

export default connect(null, mapDispatchToProps)(Contact)