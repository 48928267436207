import styled from 'styled-components'
import vars from '../../../../assets/vars.jsx'

const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;

    position: absolute;
    right: 50px;
    top: 30px;

    background-color: white;
    padding: 5px 7px;
    width: auto;

    transform: skew(-20deg);

    box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);

    &.spalshSocial {
        animation: dropDown 0.3s 0.5s linear both;
    }      

    .socialIcon {
        width: 30px;
        height: 30px;
        transform: skew(20deg);
        margin: 0 5px;

        cursor: pointer;
    }    
`

export default SocialContainer
