import { firestore } from './firebase.utils.js';

export default function Logger() {
    this.module = module;

    this.init = function (module) {
        if (JSON.parse(window.localStorage.getItem('user')) !== null) {
            this.userID = JSON.parse(window.localStorage.getItem('user')).id;
            this.logsOn = JSON.parse(window.localStorage.getItem('user')).logsOn;
        }
        this.module = module;
    }

    this.fire = async function (...message) {
        let newArray = []
        message.map(ele => {
            if (typeof ele === 'object' && ele !== null) {
                ele = JSON.stringify(ele);
            }
            newArray.push(ele);
        })
        let string = newArray.toString().replace(",", "");
        let time = new Date().toLocaleString();
        console.log(time + ": " + this.module + ": " + string);
        if (this.logsOn) {
            let logObject = {
                time: time,
                userID: this.userID,
                module: this.module,
                data: string
            }
            await firestore.collection('logs').add(logObject)
        }
    };

    this.info = function (...message) {
        let newArray = []
        message.map(ele => {
            if (typeof ele === 'object' && ele !== null) {
                ele = JSON.stringify(ele);
            }
            newArray.push(ele);
        })
        let string = newArray.toString().replace(",", "");
        let time = new Date().toLocaleString();
        console.log(time + ": " + this.module + ": " + string);
    };

    this.error = function (...message) {
        let newArray = []
        message.map(ele => {
            if (typeof ele === 'object' && ele !== null) {
                ele = JSON.stringify(ele);
            }
            newArray.push(ele);
        })
        let string = newArray.toString().replace(",", "");
        let time = new Date().toLocaleString();
        console.error(time + ": " + this.module + ": " + string);
    };
};
