import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const GorillaContainer = styled.div`
    min-height: 35px;
    width: 100%;

    background-color: #222222;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    z-index: 10;

    img {
        display: flex;
        height: 25px;
        width: 25px;
    }


    .gorillaText {
        display: flex;
        color: white;
        font-size: 10px;
        margin-right: 10px;

        span {
            color: ${vars.gold};
            margin-right: 3px;
        }
    }

`

export default GorillaContainer
