import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const ModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 5;

    background-color: rgba(22, 22, 22, 0.5);
    box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);                

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .modalDiv {
        display: flex;
        flex-direction: column;
        align-items:center;

        width: 350px;

        padding: 20px;

        background-color: white;

        transform: skew(-10deg);

        h1 {
            font-family: 'Longhaul' !important;
            transform: skew(10deg);
            line-height: 1;
        }

        p {
            transform: skew(10deg);
            line-height: 1;
        }


        .closeButton {
            background-color: red;
            padding: 5px 20px;
            transform: skew(-10deg);
            color: white;
            font-family: 'Longhaul' !important;
            cursor: pointer;
            h4 {
                transform: skew(10deg);
                margin: 0;
                line-height: 1;
            }
        }  

    }

`

export default ModalContainer
