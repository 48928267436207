// React
import React from 'react'
import { withRouter } from "react-router-dom";

// Material UI
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

// Components
import Menu from '../../components/menu/menu.component.jsx'
import Scroll from './components/scroll/scroll.component.jsx'
import Social from './components/social/social.component.jsx'
import FreeShipping from './components/freeShipping/freeShipping.component.jsx'
import Services from './components/services/services.component.jsx'
import MailingList from './components/mailingList/mailingList.component.jsx'
import Contact from './components/contact/contact.component.jsx'
import Gorilla from "../../components/gorilla/gorilla.component"

// Styling
import HomePageContainer from './home.styles'

// Logger
import Logger from '../../utils/logger.utils.js'
const Log = new Logger()
Log.init("HomePage")


const HomePage =() => {

    const goTo = (url) => {
        this.props.history.push(process.env.PUBLIC_URL + url)
    }

    return (
        <HomePageContainer>

            <div className='splash'>
                <Container className='splashContainer'>
                    <Menu/>
                    <Hidden xsDown><Social /></Hidden>
                    <img className='splashLogo' src={process.env.PUBLIC_URL + '/assets/images/BootsRebornLogoWhite.png'} alt='Boots Reborn Logo'/>
                    <h1>BRINGING OLD BOOTS BACK TO LIFE</h1>
                    <FreeShipping />
                    <Scroll />
                </Container>
            </div>

            <Services />

            <MailingList />

            <Contact />

            <Gorilla />

        </HomePageContainer>
    )
}

export default withRouter(HomePage)