import React from 'react'

// Styling
import GorillaContainer from './gorilla.styles'

const Gorilla = () => {

    return (
        <GorillaContainer onClick={() => window.open('https://www.goldgorilla.co.uk')}>
            <div className="gorillaText">
                <span>Gold Gorilla </span> Digital Design
            </div>
            <img src={process.env.PUBLIC_URL + '/assets/images/smallGorilla.png'} alt="Gold Gorilla"/>
        </GorillaContainer>
    )
}

export default Gorilla