// React
import React from 'react'

// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Styling
import ServicesContainer from './services.styles'

const Services = () =>  {

    return (
        <ServicesContainer >
            <Container id='services' className='servicesContainer' >

                <h1>SERVICES</h1>

                <Grid container spacing={3}>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/soleConversion.png)`}}></div>
                        <h3>SOLE CONVERSIONS</h3>
                        <p>Sole conversions are for those who want half and half. The grip of studs with comfort of moulded studs. All changeable back with screw in studs. please ask for more details.</p>
                    </Grid>
                    
                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/blackOut.png)`}}></div>
                        <h3>BLACK OUTS</h3>
                        <p>Blacked boots are dyeing the whole upper of the boot to give it a slick black finish. Please ask for more details.</p>
                    </Grid>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/accents.png)`}}></div>
                        <h3>COLOUR ACCENTS</h3>
                        <p>At Boots Reborn we can customize your boots to suit you. </p>
                    </Grid>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/tounge1.png)`}}></div>
                        <h3>TONGUE STRAPS</h3>
                        <p>Adding tongue straps to boots with longer tongues or replacing old straps with new premium straps</p>
                    </Grid>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/tounge2.png)`}}></div>
                        <h3>SHORT TONGUE CONVERSION</h3>
                        <p>Removing long tongues for that custom look</p>
                    </Grid>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/repairs.png)`}}></div>
                        <h3>REPAIRS</h3>
                        <p>Boot repairs can be done on some boots depending on how bad damage is.</p>    
                    </Grid>

                    <Grid item className="serviceBox" xs={12} sm={6} md={4} lg={3}>
                        <div className="serviceImage" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/boots/buy.png)`}}></div>
                        <h3>BUY &amp; SELL</h3>
                        <p>If you have boots you wish to sell to us or be sold on at a fee please get in contact</p>
                    </Grid>

                </Grid>
            </Container>
        </ServicesContainer>
    )
}

export default Services