// React
import React from 'react'

// Styling
import FreeShippingContainer from './freeShipping.styles'

const FreeShipping = () =>{

    return (
        <FreeShippingContainer>
            <h4>FREE SHIPPING ON ALL ORDERS!</h4>
        </FreeShippingContainer>
    )
}

export default FreeShipping