import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const ScrollTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 100;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    cursor: pointer;

    background-color: white;
    box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);                

    svg {
        color: black;
        font-size: 30px;
    }


`

export default ScrollTopContainer
