import styled from 'styled-components'
import vars from '../../../../assets/vars.jsx'

const ContactContainer = styled.div`
    width: 100%;

    background-color: red;
    background: linear-gradient(324deg, rgba(255,0,0,1) 0%, rgba(143,0,0,1) 100%);

    background-image: url(${process.env.PUBLIC_URL + '/assets/images/grass.png'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .contactContainer {
        padding-top: 100px;
        padding-bottom: 50px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 1px red solid; */

        h1 {
            color: white;
            font-size: 60px;
            text-align: center;
            font-family: 'Longhaul' !important;
            margin: 0 0 30px 0;
        }


        .contactRow {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;

            .boxSkew {
                height: 40px;
                background-color: white;
                margin-left: 10px;
                padding: 5px 0px;

                display: flex;
                align-items: center;

                cursor: pointer;

                transform: skew(-20deg);

                svg {
                    transform: skew(20deg);
                    margin: 0 5px;
                }

                p {
                    transform: skew(20deg);
                    font-size: 25px;
                    text-align: center;
                    font-family: 'Coolvetica' !important;
                    color: black;
                    margin: 0 15px;
                }
            }
        }
    }
`

export default ContactContainer
