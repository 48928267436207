import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const HomePageContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    background: rgb(34,34,34);
    background: linear-gradient(324deg, rgba(34,34,34,1) 0%, rgba(68,68,68,1) 34%, rgba(85,85,85,1) 100%);

    .splash {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        height: 100%;

        background-image: url(${process.env.PUBLIC_URL + '/assets/images/pitch.png'});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;

        .splashContainer {
            max-height: 100%;
            min-height: 100%;

            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* border: 1px red solid; */

            h1 {
                color: white;
                font-size: 60px;
                text-align: center;
                font-family: 'Longhaul' !important;
                margin: 0;
                animation: fadeIn 0.5s both linear;
            }

            .splashLogo {
                margin-bottom: 50px;
                width: 400px;
                height: 400px;
                border-radius: 50%;
                box-shadow: 3px 2px 18px -1px rgba(0,0,0,0.75);                
            }
        }
    }

        
    .contactUsSocial {
        position: relative;
        top: 0;
        right: 0;
    }

    @media (max-width: 768px) {
        .splash {

            .splashContainer {
                h1 {
                    font-size: 40px;
                }

                .splashLogo {
                    width: 300px;
                    height: 300px;
                }
            }
        }
        
        .hiddenMobile {
            display: none;
        }
    }
`

export default HomePageContainer
