export function scrollTop() {
  window.scrollTo({top:0, behavior:'smooth'})
}

export function scrollToServices() {
    let services = document.getElementById("services");
    let yCoord = services.getBoundingClientRect().y
    window.scrollTo({top:yCoord, behavior:'smooth'});
}

export function scrollToContact() {
    let contact = document.getElementById("contact");
    let yCoord = contact.getBoundingClientRect().y
    window.scrollTo({top:yCoord, behavior:'smooth'});
}