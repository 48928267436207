import styled from 'styled-components'
import vars from '../../assets/vars.jsx'

const CookieContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;

    width: 100%;
    min-height: 70px;

    background-color: rgba(255, 3, 0, 0.8);

    .cookiesWidth {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    p {
        color: white;
        margin: 0;
    }

    svg {
        color: white;
    }

    a {
        color: white;
        text-decoration: underline;
    }

    .closeBtn {
        background: white;

        transform: skew(-20deg);

        padding: 5px 20px;

        cursor: pointer;

        p {
            font-size: 18px;
            font-family: 'Longhaul' !important;
            margin: 5px 0 0 0;
            transform: skew(20deg);
            color: rgba(255, 3, 0, 1);
        }
    }

`

export default CookieContainer
