import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'


library.add(
    fab,
    fas,
    far
);

//
// fab,
// faCheckSquare,
// faCoffee,
// faUserAltSlash,
// faUser,
// faHome,
// faGripHorizontal,
// faEdit,
// faCog,
// faPen,
// faBorderAll
